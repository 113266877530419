import React from "react"
import Gallery from "../components/gallery"

export default function Homepage({ data, pageContext }) {
  const name = "Spaces"
  const options = {
    margin: 6,
    direction: "column",
  }
  return (
    <Gallery
      name={name}
      options={options}
      photos={data.allContentfulAsset.edges}
    />
  )
}

export const query = graphql`
  query SpaceQuery {
    allContentfulAsset(filter: { description: { eq: "spaces" } }) {
      edges {
        node {
          id
          description
          title
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`
